'use strict'
const _ = require('lodash')
function isJsonForPage(pageJson) {
    return _.get(pageJson, 'structure.type') === 'Page'
}

function removeMenuData(document_data) {
    const menuDataItems = _.pickBy(document_data, {type: 'CustomMenu'})
    const basicMenuItems = _.pickBy(document_data, {type: 'BasicMenuItem'})
    _.forOwn(menuDataItems, (v, id) => {
        delete document_data[id]
    })
    _.forOwn(basicMenuItems, (v, id) => {
        delete document_data[id]
        if (v.link) {
            delete document_data[v.link.replace('#', '')]
        }
        if (v.iconRef) {
            delete document_data[v.iconRef.replace('#', '')]
        }
    })
}

function menuDataOnPageFixer(pageJson, pageIdsArray, wrequestModel, currentUrl, urlFormatModel, isViewer, rendererModel, magicObject) {
    if (isViewer && isJsonForPage(pageJson) && magicObject.isExperimentOpen('bv_removeMenuDataFromPageJson')) {
        const {document_data} = pageJson.data
        removeMenuData(document_data)
        _.forOwn(pageJson.translations, translationLanguage => removeMenuData(translationLanguage.data.document_data))
    }
}

module.exports = {exec: menuDataOnPageFixer}
